<template>
  <!--
    nav:  styling that reduces the width v-list-item takes up as well as adding a border radius,
    dense: Lowers max height of list tiles
   -->
  <v-list nav dense v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-list>
</template>

<script>
export default {
  name: "BaseNavList",
  inheritAttrs: false
};
</script>
